import { faArrowLeft, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, ButtonGroup, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from "sweetalert2";
import NavBarBottom from "../NavBarBottom";

function CompanyDealCatInfo() {

    const navigate = useNavigate()
    const [user, setUser] = useState()

    const { id } = useParams()

    const [staff, setStaffs] = useState([]);
    const [options, setDealOptions] = useState([]);

    const [selectedDealoption, setSelectedDealoption] = useState("");

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [duration, setDuration] = useState("")
    const [price, setPrice] = useState("")
    const [qty, setQty] = useState("")
    const [img, setImage] = useState("")
    const [saving, setSaving] = useState("")
    const [com_id, setComId] = useState("")
    const [dealid, setDeal] = useState("")
    const [terms, setTerms] = useState("")

    const [cusid, setCustomer] = useState("1")

    const [salesid, setSalesStaff] = useState("0")

    const { className } = ""
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [open, setOpen] = useState('');
    const toggle2 = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    useEffect(() => {
        console.log(localStorage.getItem("customerid"))
        fetchDeal()
        fetchDealOptions()
    }, [])


    const handleOptionChange = (event) => {
        setSelectedDealoption(event.target.value);
        fetchDealById(event.target.value)
        //alert(price)        
    };


    function getExpiryDate(numOfDays) {

        const date = new Date();
        date.setDate(date.getDate() + numOfDays);
        const m = date.getMonth() + 1;
        const expdate = date.getFullYear() + "-" + m + "-" + date.getDate();
        return expdate;
    }

    const fetchDeal = async () => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/deals/${id}`).then(({ data }) => {
            const { title, description, duration_days, price, qty, image, res_id, saving, term_cond } = data.deal
            setTitle(title)
            setDescription(description)
            setDuration(duration_days)
            setPrice(price)
            setQty(qty)
            setImage(image)
            setDeal(id)
            setSelectedDealoption(id)
            setComId(res_id)
            setSaving(saving)
            fetchSalesStaff(res_id)
            setTerms(term_cond)

        }).catch(({ response: { data } }) => {

        })
    }

    const fetchDealById = async (did) => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/deals/${did}`).then(({ data }) => {
            const { title, description, duration_days, price, qty, image, res_id, saving } = data.deal
            setPrice(price)
            setQty(qty)
            setComId(res_id)
        }).catch(({ response: { data } }) => {

        })
    }



    const fetchDealOptions = async () => {

        await axios.get(`https://enter.bulkbuyme.com/api/public/api/deals_parent/${id}`).then(({ data }) => {
            setDealOptions(data)

        }).catch(({ response: { data } }) => {

        })
    }


    const BuyDeal = async (e) => {
        e.preventDefault();
        const cus_id = localStorage.getItem("customerid");
        if (cus_id) {
            setCustomer(cus_id);

            const formData = new FormData()

            formData.append('customer_id', cus_id)
            formData.append('deal_id', selectedDealoption)
            formData.append('remain', qty)
            formData.append('sales_staff_id', salesid)

            const expirydate = getExpiryDate(duration)
            formData.append('expiry_date', expirydate)

            await axios.post(`https://enter.bulkbuyme.com/api/public/api/customer_deals`, formData).then(({ data }) => {
                Swal.fire('Your Deal has been send for approval')
                navigate("/home")
            }).catch(({ response }) => {
                console.log(response)
            })
        } else {
            navigate("/signup")
        }
    }

    const fetchSalesStaff = async (cid) => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/salesstaffcomp/${cid}`).
            then(({ data }) => {
                setStaffs(data)
            });
    }

    return (
        <>
            <Container className="">
                <div className="row border" style={{ backgroundColor: "#FFF", padding: "10px" }}>
                    <div className="col-3" style={{ textAlign: "center", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <a style={{ color: "#666" }} href={`#/companydeals/${com_id}`}><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-6" style={{ paddingTop: "10px" }}>
                        <h3 style={{ color: "#666" }}>Voucher</h3>
                    </div>
                </div>
                <div className="row border border-radius-25 shadow deal-box" style={{ height: "auto", background: "linear-gradient(to right, #ccc, #ccc)" }}>
                    {/* <div style={{ textAlign: "center", borderRight: "2px dotted #999" }} className="col-4">
                        <h2 style={{ color: "#999", padding: "0", marginBottom: "0px" }}>BUY 1</h2>
                        <h2 style={{ color: "#999", padding: "0", marginBottom: "0px" }}>GET 1</h2>
                        <h2 style={{ color: "#999", padding: "0", marginBottom: "0px", fontWeight: "bold" }}>FREE</h2>
                    </div> */}
                    <div className="col-12" style={{ textAlign: "left" }}>
                    <h2>Buy 1 Get 1 Free</h2>
                        <h6 style={{ color: "#999", fontWeight: "bold", marginTop: "10px", marginLeft: "10px" }}>{title}

                        </h6>
                        <h6 style={{ color: "#999", marginTop: "10px", marginLeft: "10px" }}>Valid for {duration} Days</h6>
                    </div>
                </div>
                {/* <div className="row border gr border-radius-25 shadow deal-box">
                    <div style={{ textAlign: "center" }} className="col-6">
                        <img style={{ width: "100%", padding: "0px" }}
                            alt="Sample"
                            src={img}
                        />
                        <p style={{ color: "#fff", fontSize: "12px", padding: "0", marginTop: "5px" }}>* Valid for {duration} days</p>
                    </div>
                    <div className="col-6">
                         <p style={{color:"#FFF", textAlign:"justify"}}>{description}</p>                        
                    </div>
                </div> */}

                <div className="row block" style={{ marginTop: "20px" }}>
                    {/* <div className="col-12">
    <h5 style={{ color: "green" }}>Description</h5>
    <p>{description}</p>
  </div> */}
                    <div className="col-12">
                        <h5 style={{ color: "grey" }}>Description</h5>
                        <p>{description}</p>
                    </div>
                    <div className="col-12">
                        <h5 style={{ color: "grey" }}>Terms & Conditions</h5>
                        <p dangerouslySetInnerHTML={{ __html: terms }}></p>
                    </div>
                    {/* <div className="col-12">
                        <Accordion open={open} toggle={toggle2}>
                        <AccordionItem style={{ padding: "0" }}>
                            <AccordionHeader targetId="1">
                            <h5 style={{ color: "green" }}>Description</h5>
                            </AccordionHeader>
                            <AccordionBody accordionId="1">
                            <p>{description}</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem style={{ padding: "0" }}>
                            <AccordionHeader targetId="2">
                            <h5 style={{ color: "green" }}>Terms & Conditions</h5>
                            </AccordionHeader>
                            <AccordionBody accordionId="2">
                            <p style={{ margin: "0", padding: "0", fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: terms }} />
                            </AccordionBody>
                        </AccordionItem>
                        </Accordion>
                    </div> */}
                </div>

                <div className="row border-radius-25 shadow deal-box">
                    <div className="col-12">
                        {/* <Form onSubmit={BuyDeal}> */}
                        <Button onClick={toggle} className="buy-button  border-radius-25">Use</Button>
                        {/* </Form> */}
                    </div>
                </div>
                <div>
                    <Modal isOpen={modal} toggle={toggle} className={className} centered={true}>
                        <ModalHeader toggle={toggle}>
                            PIN Needed
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs={12}><h6>Please enter the PIN to Use the Voucher</h6></Col>
                            </Row>
                            <Row>
                                <Form>
                                    <FormGroup>
                                        <Input
                                            id="exampleEmail"
                                            name="pin"
                                            placeholder=""
                                            type="number"
                                            required
                                        />
                                    </FormGroup>
                                    <Col xs={12} md={3} style={{ textAlign: "right" }}>
                                        <Button className="border-radius-15">
                                            Confirm
                                        </Button>
                                    </Col>
                                </Form>
                            </Row>
                            {/* <Row>
                                <Col xs={6} md={9}>

                                </Col>
                                <Col xs={6} md={3} style={{ textAlign: "right" }}>
                                    <Form onSubmit={BuyDeal}>
                                        <Button className="border-radius-15">
                                            Confirm & Pay
                                        </Button>
                                    </Form>
                                </Col>
                            </Row> */}

                        </ModalBody>
                    </Modal>
                </div>
                <NavBarBottom />
            </Container>
        </>
    )
}
export default CompanyDealCatInfo;