import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Input, Row, Spinner, CardImg, CardText, Button } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import MyDeals from "./deals/MyDeals";
import SignIn from "./auth/SignIn";
import { Link, useParams } from "react-router-dom";
import DealList from "./deals/DealList";
import PopularDeals from "./deals/PopularDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClose, faSlidersH } from "@fortawesome/free-solid-svg-icons";


function Companies() {

    const { id } = useParams()
    const [user, setUser] = useState();

    const [search, setSearch] = useState("");
    const [title, setTitle] = useState("Search");

    const [loading, setLoading] = useState(false);
    const [comps, setCompanies] = useState([]);

    const fetchCompanies = async () => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/rescat/${id}`).then(({ data }) => {
            setCompanies(data)
            //console.log(data)
            setLoading(true);
        })
    }

    const fetchCompaniesSer = async () => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/search/${search}`).then(({ data }) => {
            setCompanies(data)
            //console.log(data)
            setLoading(true);
        })
    }

    useEffect(() => {
        fetchCompanies()
    }, [])

    return (
        <>
            <div className="sticky-top-bar" style={{ backgroundColor: "#FFF", padding: "10px", position: "sticky", top: "0", zIndex: "100" }}>
                <Row>
                    <div className="col-1" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <a style={{ color: "#DC3545", paddingLeft: "5px" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-8">
                        <h2 style={{ textAlign: "left", marginBottom: "0px" }}>Restaurants</h2>
                    </div>
                    <div className="col-3" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a style={{ color: "#000" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                    </div>
                </Row>
                <div className="row" style={{ backgroundColor: "#FFF", padding: "10px" }}>
                    <div className="filter-container">
                        <button className="filter-button custom-filter-button" style={{ fontSize: "20px" }}><FontAwesomeIcon icon={faSlidersH} /></button>
                        <button className="filter-badge-button filter-badge-button">Japanese</button>
                        <button className="filter-badge-button filter-badge-button">Chinese</button>
                        <button className="filter-badge-button filter-badge-button">Korean</button>
                        <button className="filter-badge-button filter-badge-button">Indian</button>
                        <button className="filter-badge-button filter-badge-button">Pakistani</button>
                        <button className="filter-badge-button filter-badge-button">Mexican</button>
                        <button className="filter-badge-button filter-badge-button">Sri Lankan</button>
                        <button className="filter-badge-button filter-badge-button">Phillipino</button>
                        {/* Add more buttons as needed */}
                    </div>
                </div>
                <div className="row mydeal-box" style={{ marginBottom: "15px", padding: "0px" }}>
                    <div className="col-12" style={{ padding: "0px" }}>
                        <Input type="text" placeholder="Search" onKeyUp={(event) => {
                            setSearch(event.target.value)
                            fetchCompaniesSer()
                        }} />
                    </div>
                </div>
                <div style={{ overflowX: "auto" }}>
                    <Row className="scroll-content" style={{ display: "flex", flexWrap: "nowrap" }}>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat3.gif" alt="Slide 1" style={{ width: "100%", height: "80px", borderRadius: "8px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Nearby</p>
                            </div>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat1.png" alt="Slide 1" style={{ width: "100%", height: "80px", borderRadius: "8px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Popular</p>
                            </div>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat4.jpg" alt="Slide 1" style={{ width: "100%", height: "70px", borderRadius: "8px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Hot Offers</p>
                            </div>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat5.png" alt="Slide 1" style={{ width: "100%", height: "85px", borderRadius: "8px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Latest Offers</p>
                            </div>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat5.gif" alt="Slide 1" style={{ width: "100%", height: "85px", borderRadius: "8px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Near Beach</p>
                            </div>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat6.gif" alt="Slide 1" style={{ width: "100%", height: "85px", borderRadius: "8px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Top Spots</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="bg-light">
                <Row>
                    <Col xs={12} md={12} lg={12} className="restaurant-spacing">
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/1`}>
                            <Card className="my-card">
                                <div className="image-overlay"></div>
                                <CardImg top src="rest1.gif" alt="Card Image" className="card-image" />
                                <CardBody className="custom-card-body">
                                    <h2 className="heading">The Hub 7</h2>
                                    <CardText className="pc-card-text">JLT, Dubai </CardText>
                                    <Button className="check-deals" color="danger">Check Deals</Button>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col xs={12} md={12} lg={12} className="restaurant-spacing">
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/2`}>
                            <Card className="my-card">
                                <div className="image-overlay"></div>
                                <CardImg top src="rest2.gif" alt="Card Image" className="card-image" />
                                <CardBody className="custom-card-body">
                                    <h2 className="heading">Hub Express</h2>
                                    <CardText className="pc-card-text">Al Fahidi, Bur Dubai</CardText>
                                    <Button className="check-deals" color="danger">Check Deals</Button>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col xs={12} md={12} lg={12} className="restaurant-spacing">
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/2`}>
                            <Card className="my-card">
                                <div className="image-overlay"></div>
                                <CardImg top src="rest2.gif" alt="Card Image" className="card-image" />
                                <CardBody className="custom-card-body">
                                    <h2 className="heading">Hub Express</h2>
                                    <CardText className="pc-card-text">Al Fahidi, Bur Dubai</CardText>
                                    <Button className="check-deals" color="danger">Check Deals</Button>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col xs={12} md={12} lg={12} className="restaurant-spacing">
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/2`}>
                            <Card className="my-card">
                                <div className="image-overlay"></div>
                                <CardImg top src="rest2.gif" alt="Card Image" className="card-image" />
                                <CardBody className="custom-card-body">
                                    <h2 className="heading">Hub Express</h2>
                                    <CardText className="pc-card-text">Al Fahidi, Bur Dubai</CardText>
                                    <Button className="check-deals" color="danger">Check Deals</Button>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                    <Col xs={12} md={12} lg={12} className="restaurant-spacing">
                        <Link style={{ textDecoration: "none" }} to={`/companydeals/2`}>
                            <Card className="my-card">
                                <div className="image-overlay"></div>
                                <CardImg top src="rest2.gif" alt="Card Image" className="card-image" />
                                <CardBody className="custom-card-body">
                                    <h2 className="heading">Hub Express</h2>
                                    <CardText className="pc-card-text">Al Fahidi, Bur Dubai</CardText>
                                    <Button className="check-deals" color="danger">Check Deals</Button>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                </Row>

                {/* {
                loading ?
                    comps.length > 0 && (
                        comps.map((row, key) => (
                            <Link style={{ textDecoration: "none" }} to={`/companydeals/${row.id}`}>
                                <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px" }}>
                                    <div className="col-3">
                                        <img className="border-radius-15" style={{ width: "100%", padding: "0px" }}
                                            alt="Sample"
                                            src={row.image}
                                        />
                                    </div>
                                    <div className="col-9">
                                        <h5 style={{ color: "#2B2B2B", marginTop: "10px" }}>{row.name}</h5>
                                        <p style={{ color: "#666", fontSize: "12px", padding: "0px" }}>{row.address}</p>
                                        <p style={{ color: "#FF1843", fontSize: "12px", padding: "0px" }}>Available Deals</p>
                                    </div>
                                </div>
                            </Link>
                        ))) : <Spinner animation="border" />
            } */}
            </Container >
            <NavBarBottom />

        </>
    )
}
export default Companies;