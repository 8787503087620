import React from "react";
import { faUser, faHome, faHistory, faBurger } from '@fortawesome/free-solid-svg-icons';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavBarBottom() {
    return (
        <>
        <div style={{height:"70px"}}></div>
        <nav className="navbar fixed-bottom shadow navbar-light" role="navigation">
            <Nav className="w-100">
                <div className="d-flex flex-row justify-content-around w-100">
                    <NavItem style={{textAlign:"center"}}>
                        <NavLink className="nav-link" activeClassName="active">
                            <a href="#/home" style={{ color: "#5E5E5E", textDecoration: "none" }}>
                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                    <FontAwesomeIcon size="sm" icon={faHome} />
                                    <div style={{ fontSize: "12px" }}>Home</div>
                                </div>
                            </a>
                        </NavLink>
                    </NavItem>
                    <NavItem style={{textAlign:"center"}}>
                        <NavLink className="nav-link">
                            <a href="#/history" style={{ color: "#5E5E5E", textDecoration: "none" }}>
                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                    <FontAwesomeIcon size="sm" icon={faBurger} />
                                    <div style={{ fontSize: "12px" }}>History</div>
                                </div>
                            </a>
                        </NavLink>
                    </NavItem>
                    <NavItem style={{textAlign:"center"}}>
                        <NavLink className="nav-link">
                            <a href="#/profile" style={{ color: "#5E5E5E", textDecoration: "none" }}>
                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                    <FontAwesomeIcon size="sm" icon={faUser} />
                                    <div style={{ fontSize: "12px" }}>Profile</div>
                                </div>
                            </a>
                        </NavLink>
                    </NavItem>                    
                </div>
            </Nav>
        </nav>
        </>
    )
}

export default NavBarBottom;