import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const SliderContainer = () => {

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 100, // Adjust the speed value
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const containerStyle = {
    height: "60vh", // Fixed height for the container
    width: "100%",
    zIndex: -1,
  };

  const imageStyle = {
    width: "100%", // Set the width to 100% to match the container width
    height: "60vh", // Set the same fixed height as the container
    objectFit: "cover",
  };


  return (
    <div className="slider-container">
      <Carousel autoPlay={true} interval={3000} infiniteLoop={true}>
        <div>
          <img src="./slide1.jpg" alt="Slide 1" />
        </div>
        <div>
          <img src="./slide2.jpg" alt="Slide 2" />
        </div>
        <div>
          <img src="./slide3.jpg" alt="Slide 3" />
        </div>
      </Carousel>
    </div>
  );
};

export default SliderContainer;
