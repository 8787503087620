import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import MyDeals from "./deals/MyDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUserLock, faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";

function Profile() {

    const navigate = useNavigate();

    const [fname, setFirstName] = useState("");
    const [lname, setLastName] = useState("");
    const [mobile, setMobile] = useState("");
    const [bday, setBday] = useState("");
    const [email, setEmail] = useState("");

    const [pass, setPassword] = useState("");
    const [cpass, setCPassword] = useState("");

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const logout = () => {

        localStorage.removeItem('user_logged_in');
        localStorage.removeItem('user_id')
        localStorage.removeItem('customerid')
        localStorage.clear()

        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'You Logged Out Successfully',
            showConfirmButton: false,
            timer: 1500
        })

        navigate('/signin')
    };

    useEffect(() => {
        const isLogged = localStorage.getItem("user_logged_in");
        if (isLogged) {
            fetchCustomer()

        } else {
            navigate("/signin")
        }

    }, [])

    const fetchCustomer = async () => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/customerinfo/${localStorage.getItem("user_id")}`).then(({ data }) => {
            //console.log(data)
            const { id, first_name, last_name, mobile, bday, email } = data.customer

            setFirstName(first_name)
            setLastName(last_name)
            setMobile(mobile)
            setBday(bday)
            setEmail(email)

        }).catch(({ response }) => {
            //console.log(response)            
        })
    }

    const changePassword = async (e) => {
        e.preventDefault();

        if (pass == cpass) {

        } else {
            Swal.fire({
                position: 'middle',
                icon: 'error',
                title: 'Passwords do not match',
                showConfirmButton: false,
                timer: 1500
            })
        }

    }

    return (
        <>

{/* className="sticky-top-bar" style={{ padding: "10px", position: "sticky", top: "0", zIndex: "100", width: "100%", '--bs-gutter-x': 0 }} */}
            <div className="sticky-top-bar" style={{ backgroundColor: "#FFF", padding: "10px", position: "sticky", top: "0", zIndex: "100" }}>
                <Row>
                    <div className="col-1" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "left" }}>
                        <a style={{ color: "#DC3545", paddingLeft: "5px" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                    </div>
                    <div className="col-8">
                        <h2 style={{ textAlign: "left", marginBottom: "0px", color: "#000" }}>Profile</h2>
                    </div>
                    <div className="col-3" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                        <a style={{ color: "#000" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                    </div>
                </Row>
                <div className="row mydeal-box" style={{ marginBottom: "15px", padding: "0px" }}>
                    <div className="col-12" style={{ padding: "0px" }}>
                        <Input type="text" placeholder="Search" onKeyUp={(event) => { }} />
                    </div>
                </div>
            </div>
            <Container className="" fluid>
                {/* <NavBarTop /> */}
                <MyDeals />
                <Row>
                    <Col>
                        <div className="row title" style={{ marginTop: "20px" }}>
                            <div className="col-12">
                                <h6>My Profile</h6>
                            </div>
                        </div>
                        <div className="row shadow border-radius-15 mydeal-box">
                            <Row style={{ padding: "10px" }}>
                                <Col xs={4} md={6}>
                                    First Name
                                </Col>
                                <Col xs={8} md={6}>
                                    {fname}
                                </Col>
                            </Row>
                            <Row style={{ padding: "10px" }}>
                                <Col xs={4} md={6}>
                                    Last Name
                                </Col>
                                <Col xs={8} md={6}>
                                    {lname}
                                </Col>
                            </Row>
                            <Row style={{ padding: "10px" }}>
                                <Col xs={4} md={6}>
                                    Mobile
                                </Col>
                                <Col xs={8} md={6}>
                                    {mobile}
                                </Col>
                            </Row>
                            <Row style={{ padding: "10px" }}>
                                <Col xs={4} md={6}>
                                    Birthday
                                </Col>
                                <Col xs={8} md={6}>
                                    {bday}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="row title" style={{ marginTop: "20px" }}>
                            <div className="col-12">
                                <h6>My Account</h6>
                            </div>
                        </div>
                        <div className="row shadow border-radius-15 mydeal-box">
                            <Row style={{ padding: "10px" }}>
                                <Col xs={4} md={6}>
                                    Email
                                </Col>
                                <Col xs={8} md={6}>
                                    {email}
                                </Col>
                            </Row>
                            <Row style={{ padding: "10px" }}>
                                <Col xs={4} md={4}>
                                    Password
                                </Col>
                                <Col xs={3} md={4}>
                                    ***************
                                </Col>
                                <Col style={{ textAlign: "right" }} xs={4} md={4}>
                                    <a onClick={toggle} style={{ color: "red", textDecoration: "none" }}>
                                        <span style={{ fontSize: "12px" }}>Change </span> <FontAwesomeIcon size="sm" icon={faEdit} />
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row style={{ padding: "10px" }}>
                    <Col>
                        <button onClickCapture={logout} className="logout-button border-radius-25" >Logout</button>
                    </Col>
                </Row>
                <div>
                    <Modal isOpen={modal} toggle={toggle} centered={true}>
                        <ModalHeader toggle={toggle}>
                            Change Password
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Form onSubmit={changePassword}>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input
                                                id="pas"
                                                name="password"
                                                required
                                                placeholder="New Password"
                                                type="password"
                                                onChange={(event) => {
                                                    setPassword(event.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                id="cpas"
                                                name="cpassword"
                                                required
                                                placeholder="Confirm Password"
                                                type="password"
                                                onChange={(event) => {
                                                    setCPassword(event.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={3} style={{ textAlign: "right" }}>
                                        <Button className="border-radius-15 gr">
                                            Change
                                        </Button>
                                    </Col>
                                </Form>
                            </Row>
                        </ModalBody>
                    </Modal>
                </div>
                <NavBarBottom />
            </Container>
        </>
    )
}
export default Profile;