import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, CardImg, CardText, Col, Container, Row, Spinner } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import MyDeals from "./deals/MyDeals";
import SignIn from "./auth/SignIn";
import { Link } from "react-router-dom";
import DealList from "./deals/DealList";
import PopularDeals from "./deals/PopularDeals";
import PopularCompanies from "./PopularCompanies";
import Banner from "./Banner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderContainer from "./Slider";
import CustomCard from "./Welcome";
import 'normalize.css';

function Home() {
    const [user, setUser] = useState();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cats, setCategories] = useState([])

    useEffect(() => {
        fetchProducts()
        fetchCat()

        const interval = setInterval(() => {
            const usname = localStorage.getItem("user_logged_in");
            if (usname) {
                setUser(usname);
            }
        }, 1000);

        return () => clearInterval(interval);

    }, [])

    const fetchProducts = async () => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/deals`).
            then(({ data }) => {
                setProducts(data)
                setLoading(true);
            }).catch(function (error) {
            });
    }

    const fetchCat = async () => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/categories`).then(({ data }) => {
            setCategories(data);
            setLoading(true); // Add parentheses for setLoading
        })
    }

    return (
        <div style={{ backgroundColor: "#fff", padding: 0, maxWidth: "100%", overflow: "hidden" }}>
            <Container style={{ margin: "0 auto", maxWidth: "100%", padding: "0px" }}>
                <NavBarTop />
                <Row>
                    <Col md={12} className="d-none d-md-block">
                        <img style={{ width: "100%", height: "400px" }} src="banner2.png" alt="Banner" />
                    </Col>
                </Row>
                <SliderContainer/>
                <div className="position-relative">
                    <CustomCard title="Custom Card" content="This is a custom card component." width="80%" height="50px" style={{ top: "70%", left: "50%", transform: "translate(-50%, -50%)" }} />
                </div>
            </Container>
            <Container style={{ backgroundColor: "#fff", padding: 0, maxWidth: "100%" }}>
                {/* <div className="row title">
                    <div className="col-12">
                        <h6>Categories</h6>
                    </div>
                </div> */}
                {/* <Row className="title mydeal-box" style={{ marginTop: "15%" }}>
                    {loading ? (
                        cats.length > 0 &&
                        cats.map((row, key) => (
                            <Col xs={3} md={3} lg={3} style={{ paddingRight: "3px" }}>
                                <Link style={{ textDecoration: "none" }} to={`/comp/${row.id}`}>
                                    <Card className="border-radius-15 shadow" style={{ width: "100%", padding: "0px" }}>
                                        <CardBody style={{ padding: "0px" }}>
                                            <img style={{ width: "100%" }} src={row.image} alt={row.name} />
                                        </CardBody>
                                    </Card>
                                </Link>
                                <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                    <p style={{ fontSize: "12px", fontWeight: "bold" }}>{row.name}</p>
                                </div>
                            </Col>
                        ))
                    ) : (
                        <Spinner animation="border" />
                    )}
                </Row> */}
                <div style={{ overflowX: "auto" }}>
                    <Row className="mydeal-box scroll-content" style={{ display: "flex", flexWrap: "nowrap", marginTop: "19%" }}>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat3.gif" alt="Slide 1" style={{ width: "100%", height: "80px", borderRadius: "14px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Restaurants</p>
                            </div>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat1.png" alt="Slide 1" style={{ width: "100%", height: "80px", borderRadius: "14px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Salons & Spa</p>
                            </div>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat4.jpg" alt="Slide 1" style={{ width: "100%", height: "70px", borderRadius: "14px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Gifts</p>
                            </div>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat5.png" alt="Slide 1" style={{ width: "100%", height: "85px", borderRadius: "14px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Travel</p>
                            </div>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat5.gif" alt="Slide 1" style={{ width: "100%", height: "85px", borderRadius: "14px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Fashion</p>
                            </div>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="border-radius-15 shadow" style={{ width: "90%", padding: "0px", height: "80px" }}>
                                    <img src="./cat6.gif" alt="Slide 1" style={{ width: "100%", height: "85px", borderRadius: "14px" }} />
                                </Card>
                            </Link>
                            <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>Services</p>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* <Row className="title mydeal-box" style={{ display: "flex", flexWrap: "nowrap", overflowX: "scroll", marginTop: "0%" }}>
                    <Col xs={8} md={8} lg={8} style={{ padding: "3px" }}>
                        <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                            <div className="button-style1">
                                <p style={{ marginBottom: "0px", }}>All</p>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={4} md={4} lg={4} style={{ padding: "3px" }}>
                        <Link style={{ textDecoration: "none" }} to={`/comp/1`}>
                            <div className="button-style2">
                                <p style={{ marginBottom: "0px" }}>Bulk Buy</p>
                            </div>
                        </Link>
                    </Col>
                </Row>
                <Row className="title mydeal-box" style={{ display: "flex", flexWrap: "nowrap", overflowX: "scroll", marginTop: "0%" }}>
                    <Col xs={6} md={6} lg={6} style={{ padding: "3px" }}>
                        <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                            <div className="button-style3">
                                <p style={{ marginBottom: "0px" }}>Buy 1 Get 1</p>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={6} md={6} lg={6} style={{ padding: "3px" }}>
                        <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                            <div className="button-style4">
                                <p style={{ marginBottom: "0px" }}>Cash Back</p>
                            </div>
                        </Link>
                    </Col>
                </Row> */}

                <div style={{ width: "100%", overflowX: "auto", whiteSpace: "nowrap" }}>
                    <Row className="mydeal-box" style={{ display: "flex", flexWrap: "nowrap", marginTop: "0%", alignItems: "flex-start" }}>                        
                        <Col xs={3} md={3} lg={3} style={{ padding: "5px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/1`}>
                                <Card className="imagecard2" style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", borderRadius: "15px" }}>
                                    <CardImg src="discount.png" alt="Image 2" style={{ maxHeight: "auto", float: "right", width: "100%", borderRadius: "15px" }} />
                                </Card>
                                <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                    <CardText style={{ marginBottom: "0px", color: "#3F494E", fontWeight: "bold" }}>Discount</CardText>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={3} md={3} lg={3} style={{ padding: "5px" }}>
                            <Link style={{ textDecoration: "none" }} to={`/comp/2`}>
                                <Card className="imagecard3" style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", borderRadius: "15px" }}>
                                    <CardImg src="buy1get1.png" alt="Image 3" style={{ maxHeight: "auto", float: "right", width: "100%", borderRadius: "15px" }} />
                                </Card>
                                <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                    <CardText style={{ marginBottom: "0px", color: "#3F494E", fontWeight: "bold" }}>Buy 1 Get 1</CardText>
                                </div>
                            </Link>
                        </Col>                        
                    </Row>
                </div>                
                {/* {user ? <MyDeals /> : ""} */}
                <PopularCompanies />
                <NavBarBottom />
            </Container >
        </div >
    )
}

export default Home;
