import React, { useEffect, useState } from "react";
import { Card, CardHeader, Col, Container, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import { useNavigate } from "react-router-dom";


function Menu() {

    const [loading, setLoading] = useState(true);

    const hideSpinner = () => {
        setLoading(false);
    };

    return (
        <>
            <Container className="bg-light" fluid>
                {
                  loading ? <Spinner animation="border" /> : null
                }
                <iframe onLoad = {hideSpinner} style={{ width: "100%", height: "1000px" }} src="https://www.godinein.com/site/restaurant/the-hub-7/1"></iframe>
                <NavBarBottom />

            </Container>
        </>
    )
}

export default Menu;