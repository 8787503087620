import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Spinner, Row } from 'reactstrap';
import NavBarBottom from "../NavBarBottom";
import NavBarTop from "../NavBarTop";
import MyDeals from "./MyDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faLocation, faLocationArrow, faPhoneAlt, faClose } from "@fortawesome/free-solid-svg-icons";

function DealHistory() {

    const navigate = useNavigate()

    const [receipts, setReceipts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const isLogged = localStorage.getItem("user_logged_in");
        if (isLogged) {
            fetchReceipts()
        } else {
            navigate("/signin")
        }
    }, [])

    const fetchReceipts = async () => {
        await axios.get(`https://enter.bulkbuyme.com/api/public/api/receiptcus/${localStorage.getItem("customerid")}`).then(({ data }) => {
            setReceipts(data)
            //console.log(data)
            setLoading(true);
        })
    }

    return (
        <>
            <Row className="sticky-top-bar" style={{ background: "linear-gradient(to bottom, black, transparent)", padding: "10px", top: "0", zIndex: "100", width: "100%", '--bs-gutter-x': 0 }}>
                <div className="col-1" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "left" }}>
                    <a style={{ color: "#DC3545", paddingLeft: "5px" }} href="#/home"><FontAwesomeIcon size="lg" icon={faArrowLeft} /></a>
                </div>
                <div className="col-8">
                    <h2 style={{ textAlign: "left", marginBottom: "0px", color: "#fff" }}>History</h2>
                </div>
                <div className="col-3" style={{ textAlign: "right", border: "0px solid #000", display: "flex", alignItems: "center", justifyContent: "right" }}>
                    <a style={{ color: "#fff" }} href="#/home"><FontAwesomeIcon size="lg" icon={faClose} /></a>
                </div>
            </Row>

            <Container className="" fluid>

                <div className="row title" style={{ marginTop: "20px" }}>
                    <div className="col-12">
                        <h6>Your Redeem History </h6>
                    </div>
                </div>
                <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "10px", paddingTop: "10px" }}>
                    {
                        loading ? receipts.length > 0 && receipts.map
                            ((row, key) => (
                                <div className="row title" style={{ borderBottom: "1px solid #CCC", marginBottom: "10px" }}>
                                    <div className="col-4">
                                        <p style={{ fontSize: "12px", margin: "10px" }}>{row.date}</p>
                                    </div>
                                    <div className="col-5">
                                        <p style={{ fontSize: "14px", margin: "10px" }}>{row.title}</p>
                                    </div>
                                    <div className="col-3" style={{ textAlign: "right" }}>
                                        <p style={{ margin: "10px" }}>{row.qty}</p>
                                    </div>
                                </div>
                            )) : <Spinner animation="grow" />
                    }
                </div>
                <NavBarBottom />
            </Container>
        </>
    )
}
export default DealHistory;