import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import NavBarBottom from "../NavBarBottom";
import Swal from 'sweetalert2'

function ForgetPassword() {

    const navigate = useNavigate();

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const [validationError, setValidationError] = useState({})

    const login = async (e) => {

        e.preventDefault();

        const formData = new FormData()

        formData.append('email', email)
        formData.append('password', password)

        await axios.post('https://bulkbuy.godinein.com/public/api/forget', formData).then(({ data }) => {
            console.log(data)
            //console.log(localStorage.getItem("user"))
            Swal.fire('Password Reset Request Sent Successful')
            //navigate("/home")
        }).catch(({ response }) => {
            console.log(response)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid Email !!',
            })
        })
    }

    return (
        <>
            <Container style={{ backgroundColor: "#FFF" }} fluid>
                <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">
                        <img style={{ width: "100px" }} src="logo.jpg" />
                    </div>
                </div>
                <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">
                        <h3>Reset Password</h3>
                        <hr />
                    </div>
                </div>
                <Form onSubmit={login}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Email
                                </Label>
                                <Input
                                    id="exampleEmail"
                                    name="email"
                                    required
                                    placeholder="Email"
                                    type="email"
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>                        
                    </Row>
                    <Button className="logout-button border-radius-15">
                        Reset
                    </Button>
                </Form>
                <div className="row" style={{ marginTop: "20px", textAlign: "center", backgroundColor: "#FFF" }}>
                    <div className="col-12">
                        <hr />
                        <p style={{ color: "#666" }}>Don't have an account? <a style={{ textDecoration: "none", color: "red" }} href="#/signup">Sign up</a> </p>
                    </div>
                </div>
                <NavBarBottom />
            </Container>
        </>
    )
}
export default ForgetPassword;